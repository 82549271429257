import React, { useRef, useState } from "react";
import {
  FreeMode,
  Autoplay,
  Pagination,
  Navigation,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import teacher from "../../img/ustozlar.jpg";
import Img_one from "../../img/img1.jpg";
import Img_two from "../../img/img2.jpg";
import Img_three from "../../img/img3.jpg";
import Img_four from "../../img/img4.jpg";
import Img_five from "../../img/img5.jpg";
import Img_six from "../../img/img6.jpg";
import Img_seven from "../../img/img7.jpg";
import Img_eight from "../../img/img8.jpg";
import Img_nine from "../../img/img9.jpg";
import "./carousel.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export default function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={30}
        slidesPerView={1}
        centeredSlides={true}
        thumbs={{ swiper: thumbsSwiper }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation, Thumbs]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper2"
      >
        <SwiperSlide>
          <img alt="img" src={Img_one} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={teacher} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_two} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_three} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_four} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_five} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_six} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_seven} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_eight} />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="img" src={Img_nine} />
        </SwiperSlide>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
      <Swiper
        onClick={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img alt="slider" src={Img_one} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={teacher} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_two} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_three} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_four} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_five} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_six} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_seven} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_eight} className="border_radios" />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="slider" src={Img_nine} className="border_radios" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
