import React from "react";
import "./style.css";

export default function Location() {
  return (
    <div className="location">
      <iframe
        width="100%"
        height="400"
        title="Oriental Private School"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Oriental%20private%20school%20n.t.m+(School%20location)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
        <a href="https://www.gps.ie/">gps systems</a>
      </iframe>
    </div>
  );
}
