import React, { useState } from "react";
import { Button } from "antd";
import Popover from "./popover/popover";
import Logo from "../../img/logo.png";

import "./style.css";

export default function Navbar() {
  const [isDark] = useState(true);

  return (
    <div className="navbar" data-theme={isDark ? "dark" : "light"}>
      <div className="containers">
        <div className="flexs">
          <div className="nav_img_div">
            <img src={Logo} alt="" className="nav_img" />
          </div>
          <div className="nav_ul">
            <ul className="ul">
              <li>Bosh sahifa</li>
              <li>Yangiliklar</li>
              <li>Biz haqimizda</li>
              <a href="https://edu-platform.uz/">
                <Button className="bg_color">Platforma</Button>
              </a>
            </ul>
          </div>
          <div className="nav_popover">
            <Popover />
          </div>
        </div>
      </div>
    </div>
  );
}
