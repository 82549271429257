import React, { useState } from "react";
import { Button } from "antd";
import Carousel from "../../dashboard/carousel/carousel";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import teacher from "../../img/ustozlar.jpg";

import Img_4 from "../../img/img4.jpg";
import Img_5 from "../../img/img5.jpg";

import Boy from "../../img/img-biz-haqimzda.jpg";
import Location from "../../dashboard/location/Location";
import { BsTelephoneFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaUser } from "react-icons/fa";
import TextArea from "antd/es/input/TextArea";
import "./header.css";

export default function Header() {
  const [number, setNumber] = useState("uz");

  const [text] = useTypewriter({
    words: [
      "Chet tillarini o'rganish uchun qulay sharoit!",
      "Dasturlashni o'rganish uchun yaxshi imkoniyat!",
      "Til o'rganish uchun oson yondashuv va muhit!",
      "Farzandingiz uchun sifatli ta'lim dargohi!",
      "Malakali o'qituvchi va kadrlar jamoasi!",
      "Siz izlagan maskan!",
    ],
    loop: {},
    typeSpeed: 20,
    deleteSpeed: 5,
  });

  return (
    <div className="header">
      <div className="containers">
        <div className="header_flexs">
          <div className="header_flexs_text">
            <h4 className="fw-bolder">Bizning maktabimiz </h4>
            <h2 className="text-primary my-3 fw-bolder">
              Oriental Private School
            </h2>
            <h5 className="zamonaviy_class fw-700">
              Zamonaviy ta'lim va sharqona tarbiya olish uchun eng yaxshi
              tanlov! Bizda arab tili, rus tili, ingliz tili va dasturlash
              kurslari mavjud. Darslar haftasiga 5 kun, malakali ustozlar
              tomonidan olib boriladi.
            </h5>
            <p className="typing text-primary my-3">
              {text}
              <span style={{ color: "red" }}>
                <Cursor cursorStyle="|" />
              </span>
            </p>

            <a href="https://edu-platform.uz/">
              <Button className="">Platforma</Button>
            </a>
          </div>
          <div className="carousels">
            <Carousel />
          </div>
        </div>
        <div className="row my-3">
          <h3 className="text-center fw-bolder">Foto Lavhalar</h3>

          <div className="col-md-4 col-lg-3 col-sm-6 mt_potion">
            <div className="card rounded overflow-hidden">
              <img src={teacher} className="img-fluid" alt="..."></img>
              <p className=" fs-5 pt-2 px-2">
                1-oktyabr o'qituvchilar va murabbiylar kuni
              </p>
            </div>
          </div>

          <div className="col-md-4 col-lg-3 col-sm-6 mt_potion">
            <div className="card rounded overflow-hidden">
              <img src={Img_4} className="img-fluid" alt="..."></img>
              <p className=" fs-5 pt-2 px-2">Bilimlar xazinasi tadbiri</p>
            </div>
          </div>

          <div className="col-md-4 col-lg-3 col-sm-6 mt_potion">
            <div className="card rounded overflow-hidden">
              <img src={Img_5} className="img-fluid" alt="..."></img>
              <p className=" fs-5 pt-2 px-2">Tadbir yakunida</p>
            </div>
          </div>
        </div>
        <div></div>
        <div className="testimonial">
          <h1 className="h1">Biz haqimizda</h1>
          <div className="testimonial_footer">
            <div className="img_cart_header">
              <img src={Boy} alt="" className="testimonial_footer_img" />
            </div>
            <div className="malumotlar">
              <p className="p">
                Farzandlar👧🏻 kelajagiga befarq bo’lmagan holda ko’proq bilim
                olishga chorlash🧠, harakatlariga javoban munosib baholash,
                rag’batlantirish🏆 - ularda yangidan yangi bilimlarni egallashga
                motivatsiya✨bo’ladi va bu olgan bilimlari katta hayotga qadam
                qo’yganlarida asqotadi InshaAllah😇
              </p>
              <div>
                <p className="color_text_30">30</p>
                <p> dan ortiq ustozlarimiz</p>
              </div>
              <div>
                <p className="color_text_200">200</p>
                <p>dan ortiq o'quvchilarimiz</p>
              </div>
            </div>
          </div>
        </div>
        <div className="location_call">
          <div>
            <Location />
          </div>
          <div className="phone_cart">
            <h1 className="malumot">Ma'lumot olish</h1>
            <p className="ariza">
              * Arizani yuborishdan oldin, to'g'ri ma'lumot kiritganingizga
              ishonch hosil qiling
            </p>
            <div className="flex_div_cart">
              <div className="boglanish">
                <FaUser className="FaUser" />
                <input type="text" placeholder="Username" />
              </div>
              <div className="phone_number">
                <BsTelephoneFill className="BsTelephoneFill" />
                <PhoneInput
                  country={number}
                  placeholder="Phone number"
                  onChange={(value) => {
                    setNumber(value);
                  }}
                  inputStyle={{ border: "none", backgroundColor: "white" }}
                  className="PhoneInput"
                />
              </div>
            </div>
            <div>
              <TextArea
                rows={4}
                placeholder="Information"
                className="TextArea_inp"
              />
            </div>
            <button className="yuborish_btn">Yuborish</button>
          </div>
        </div>
      </div>
    </div>
  );
}
