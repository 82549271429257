import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import "../style.css";

const App = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <AiOutlineMenu onClick={showDrawer} className="text-[20px]" />
      <Drawer onClose={onClose} open={open}>
        <ul className="ul">
          <li className="mt_li">Bosh sahifa</li>
          <li className="mt_li">Biz haqimizda</li>
          <a href="https://edu-platform.uz/">
            <Button className="mt_li_btn">Platforma</Button>
          </a>
        </ul>
      </Drawer>
    </div>
  );
};
export default App;
