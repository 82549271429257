import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import Vedioss from "./vedioss.mp4";
import { FaArrowLeft } from "react-icons/fa6";
import "./style.css";

export default function VideoPlayer() {
  return (
    <div>
      <a href="/" className="a">
        <div className="chiqish">
          <FaArrowLeft />
          <h1 className="chiqish_text">Chiqish</h1>
        </div>
      </a>
      <div className="vedio">
        <Video
          autoPlay
          loop
          muted={1.0 === 0}
          className="vedio_see"
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
          volume={1.0}
          onCanPlayThrough={() => {}}
        >
          <source src={Vedioss} type="video/webm" />
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src="http://source.vtt"
            default
          />
        </Video>
      </div>
    </div>
  );
}
