import React from "react";
import "./style.css";

export default function Footer() {
  return (
    <div className="footer">
        <span>Barcha huquqlar himoyalangan © 2024 OPS NTM</span>
    </div>
  );
}
